import React from 'react'
import LayoutGlobal from '../components/LayoutGlobal'
import Seo from '../components/Seo'
import Video from '../components/Video'
import ToggleStudio from '../components/ToggleStudio'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

export default function Studio() {
  return (
    <LayoutGlobal>

      <Seo 
        title="Zobacz Studio"
        description="Krótka wycieczka po przestrzeni Studia Medyczno-Treningowego."
        url="https://www.fizjopersonalny.pl/studio"
      />

      <section>
        <div className='studio_box'>
          <h1>Studio Medyczno-Treningowe</h1>
          <div className='studio_grid studio_margin_intro'>
            <div className='studio_intro'>
              <p>Moje Studio to prywatna przestrzeń dla każdego, kto ceni sobie wygodę i prawdziwie indywidualne podejście. Jeśli z różnych względów, trenowanie w komercjalnych klubach fitness, nie jest dla Ciebie komfortowym przeżyciem to moje Studio jest właśnie dla Ciebie.</p>
              <blockquote>Polecam treningi z Łukaszem. Bardzo profesjonalnie podchodzi do współpracy, a treningi przebiegają w przyjemniej atmosferze. Treningi w prywatnym studio są dużo fajniejsze niż na zatłoczonej siłowni.<cite> Adam</cite></blockquote><br />
              <p>Zobacz film i przekonaj się jak Studio wygląda w środku. Poniżej znajdziesz również szczegółowy opis każdego z pomieszczeń.</p>
            </div>
            <div className='studio_video'>
              <Video
                videoSrcURL="https://www.youtube.com/embed/AqErUzHokcU"
                videoTitle="Studio Medyczno-Treningowe"
              />
            </div>
          </div>
        </div>
      </section>

      <div className='about_menu'>
        <Link to="#sala-cwiczen">sala ćwiczeń</Link>
        <Link to="#gabinet">gabinet</Link>
        <Link to="#poczekalnia">poczekalnia</Link>
        <Link to="#szatnia">szatnia</Link>
        <Link to="#lazienka">łazienka</Link>
        <Link to="#parking">parking</Link>
      </div>

      <section id="sala-cwiczen">
      <div className='studio_grid studio_margin_left'>
        <div className='studio_img_left'>
          <StaticImage src="../images/studio/sala-cwiczen.jpg" alt="FizjoPersonalny - Sala ćwiczeń" />
        </div>
        <div className='studio_content'>
          <h2>Sala ćwiczeń</h2>
          <p>Tutaj realizowane są treningi oraz ćwiczenia rehabilitacyjne. Sala wyposażona jest w sprzęt niezbędny do przeprowadzenia treningu oraz terapii. Poniżej znajdziesz pełną listę wyposażenia.</p><br />

          <ToggleStudio title="Trening siłowy">
            <ul>
              <li>gryf olimpijski 15 kg (28 mm, 180 cm)</li>
              <li>gryf olimpijski 20 kg (28 mm, 220 cm)</li>
              <li>gryf olimpijski łamany 10 kg (28 mm, 120 cm)</li>
              <li>talerze obciążeniowe BUMPER (4x 5 kg; 4x 10 kg; 2x 15 kg; 2x 20 kg)</li>
              <li>talerze obciążeniowe żeliwne (4x 1,25 kg; 2x 2,5 kg)</li>
              <li>hantle żeliwne (zakres obciążenia od 1,5 kg do 21,5 kg)</li>
              <li>klatka rack z drążkiem do podciągania (udźwig do 300 kg)</li>
              <li>ławka treningowa prosta, płaska (udźwig do 400 kg)</li>
              <li>poręcze stacjonarne (udźwig do 290 kg)</li>
              <li>odważnik kettlebell żeliwny 6 kg</li>
              <li>odważnik kettlebell żeliwny 12 kg</li>
              <li>piankowy ochraniacz na sztangę</li>
            </ul>
          </ToggleStudio>

          <ToggleStudio title="Gumy oporowe">
            <ul>
              <li>system gum oporowych Bodylastics® (kompletny zestaw)</li>
              <li>gumy długie typu powerband (5 poziomów oporu)</li>
              <li>gumy krótkie typu miniband (4 poziomy oporu)</li>
              <li>gumy krótkie typu miniband (materiałowe, 5 poziomów oporu)</li>
              <li>taśmy rehabilitacyjne (3 poziomy oporu)</li>
            </ul>
          </ToggleStudio>

          <ToggleStudio title="Mobilizacje i rozluźnienie tkanek">
            <ul>
              <li>wałek do masażu gładki (twardy)</li>
              <li>wałek do masażu gładki (średnio twardy)</li>
              <li>wałek do masażu z wypustkami (średnio twardy)</li>
              <li>piłka do masażu typu duball</li>
              <li>piłki do masażu (twarde, różne średnice)</li>
              <li>taśma do Flossingu</li>
              <li>pistolet do masażu (różne końcówki)</li>
            </ul>
          </ToggleStudio>

          <ToggleStudio title="Pozostały sprzęt">
            <ul>
              <li>rower powietrzny (airbike)</li>
              <li>maty do ćwiczeń</li>
              <li>stepy do ćwiczeń (udźwig do 250 kg)</li>
              <li>dysk sensomotoryczny</li>
              <li>platforma do trenignu równoważnego</li>
              <li>drabinka koordynacyjna (piłkarska)</li>
              <li>uchwyty do pompek</li>
              <li>deskorolka</li>
              <li>piłka rehabilitacyjna</li>
              <li>nawilżacz powietrza</li>
              <li>klimatyzator</li>
            </ul>
          </ToggleStudio><br />

          <p>Używany w trakcie treningu i terapii sprzęt jest dezynfekowany po każdym kliencie. Przez cały rok temperatura utrzymywana jest na poziomie 18-21 stopni, natomiast wilgotność w okolicy 40%.</p>
        </div>
      </div>
      </section>

      <section className='about_bar'>
        <div className='about_bar_text'>Szukasz fizjoterapeuty i trenera, który pomoże Ci zwiększyć sprawność?</div>
        <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer" className='button'>Napisz do mnie</a>
      </section>

      <section id="gabinet">
      <div className='studio_grid studio_margin_right'>
        <div className='studio_content'>
          <h2>Gabinet terapii i masażu</h2>
          <p>W tym miejscu przeprowadzane są konsultacje fizjoterapeutyczne oraz prowadzona jest terapia z wykorzystaniem metod i technik terapii manualnej oraz powięziowej. Tutaj również wykonywane są masaże lecznicze.</p><br />
          <blockquote>Pan Łukasz to doskonały specjalista - od pierwszych minut wizyty czuć że z pasją podchodzi do swojej pracy; z dużym zaangażowaniem zajął się moim problemem. <cite> Izabela</cite></blockquote><br />
          <p>Gabinet wyposażony jest w sprzęt niezbędny do przeprowadzenia specjalistycznej terapii oraz wykonania masażu leczniczego. Pomieszczenie to jest odizolowane drzwiami wewnętrznymi od reszty Studia, co zapewnia zachowanie prywatności.</p>
        </div>
        <div className='studio_img_right'>
          <StaticImage src="../images/studio/gabinet.jpg" alt="FizjoPersonalny - Gabinet" />
        </div>
      </div>
      </section>

      <section className='about_bar'>
        <div className='about_bar_text'>Chcesz się pozbyć nieznośnego bólu między łopatkami?</div>
        <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer" className='button'>Napisz do mnie</a>
      </section>

      <section id="poczekalnia">
      <div className='studio_grid studio_margin_left'>
        <div className='studio_img_left'>
          <StaticImage src="../images/studio/poczekalnia.jpg" alt="FizjoPersonalny - Poczekalnia" />
        </div>
        <div className='studio_content'>
          <h2>Poczekalnia</h2>
          <p>To pomieszczenie przeznaczone jest do oczekiwania na trening, terapię czy masaż. Poczekalnia wyposażona jest w wygodną kanapę oraz stolik. W razie potrzeby możesz również zamknąć drzwi i w spokoju odizolować się od reszty Studia (jeśli np. chcesz porozmawiać przez telefon).</p><br />
          <blockquote>Łukasz pokazał mi jak trenować z ciężarkami, sztangą i taśmami, i jak korygować obszary, które nie są zrównoważone. Do tego naprawdę świetna atmosfera, ciekawe rozmowy i bardzo komfortowe studio - zdecydowanie będę wracać i polecać znajomym! <cite> Maja</cite></blockquote><br />
          <p>Podczas oczekiwania koniecznie skorzystaj z możliwości wypicia smacznej kawy. W tym miejscu znajdziesz również szklane bidony oraz przeflitrowaną wodę, która przyda się do uzupełnienia płynów podczas treningu lub po masażu.</p>
        </div>
      </div>
      </section>

      <section className='about_bar'>
        <div className='about_bar_text'>Doskwiera Ci ból pleców z promieniowaniem do pośladka?</div>
        <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer" className='button'>Napisz do mnie</a>
      </section>

      <section id="szatnia">
      <div className='studio_grid studio_margin_right'>
        <div className='studio_content'>
          <h2>Szatnia</h2>
          <p>Szatnia to miejsce służące do pozostawienia okrycie wierzchniego. Dodatkowo, jest ona wyposażona w dwie, odizolowane od siebie kabiny, gdzie w spokoju przebierzesz się w lżejszy strój do ćwiczeń i zostawisz swoje rzeczy.</p><br />
          <blockquote>Łukasz idealnie dobrał ćwiczenia do mojego schorzenia (chondromalacja rzepki), dzięki czemu ćwiczę bez bólu a sama aktywność fizyczna odpowiednio wzmocniła mięśnie. <cite> Paulina</cite></blockquote><br />
          <p>Tutaj również możesz odizolować się od reszty Studia za pomocą drzwi. Wewnątrz znajduje się również lustro, w którym sprawdzisz jak dobrze wyglądasz.</p>
        </div>
        <div className='studio_img_right'>
          <StaticImage src="../images/studio/szatnia.jpg" alt="FizjoPersonalny - Szatnia" />
        </div>
      </div>
      </section>

      <section className='about_bar'>
        <div className='about_bar_text'>Szukasz sposobu, aby przestać się garbić?</div>
        <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer" className='button'>Napisz do mnie</a>
      </section>

      <section id="lazienka">
      <div className='studio_grid studio_margin_left'>
        <div className='studio_img_left'>
          <StaticImage src="../images/studio/lazienka.jpg" alt="FizjoPersonalny - Toaleta" />
        </div>
        <div className='studio_content'>
          <h2>Łazienka</h2>
          <p>Łazienka jest pomieszczeniem, w którym potrzebujesz najwięcej prywatności. Z tego względu znajdziesz tutaj wszystko to, co niezbędne do odświeżenia się po treningu, terapii czy masażu.</p><br />
          <blockquote>Zajęcia  z Łukaszem pomogły mi powróć do dobrej formy po kontuzji kręgosłupa. Jego profesjonalne podejście oraz kreatywność wpłynęły bardzo pozytywnie na moją motywację. <cite> Krzysztof</cite></blockquote><br />
          <p>Wewnątrz znajdziesz odświeżający koszyczek z najpotrzebniejszymi rzeczami pierwszej potrzeby: antyperspirant w dezodorancie (damski, męski), nitkę do zębów, gumę do włosów, krem do rąk oraz kobiece artykuły higieniczne.</p>
        </div>
      </div>
      </section>

      <section className='about_bar'>
        <div className='about_bar_text'>Chcesz poprawić wygląd swojej sylwetki?</div>
        <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer" className='button'>Napisz do mnie</a>
      </section>

      <section id="parking">
      <div className='studio_grid studio_margin_right'>
        <div className='studio_content'>
          <h2>Parking</h2>
          <p>Planujesz przyjechać samochodem? Świetnie! Na miejscu zawsze znajdziesz miejsce parkingowe na czas trwania wizyty. Parkowanie jest bezpłatne i nie wymaga identyfikatora. </p><br />
          <blockquote>Treningi z Łukaszem to niezawodny sposób na polubienie sportu. Jego nastawienie i dowcip, jak również spora wiedza i zaangażowanie sprawia, że każdy trening to przyjemność równa zjedzeniu Big Maca i 2 czekolad. <cite> Dorota</cite></blockquote><br />
          <p> Z uwagi na to, że część miejsc parkingowych znajduje się na parkingu zamkniętym, poinformuj mnie o przyjeździe samochodem przed wizytą. Zaparkuj na dowolnym miejscu, z uwzględnieniem wskazówek umieszczonych na tablicach informacyjnych.</p>
        </div>
        <div className='studio_img_right'>
          <StaticImage src="../images/studio/parking.jpg" alt="FizjoPersonalny - Parking" />
        </div>
      </div>
      </section>

    </LayoutGlobal>
  )
}